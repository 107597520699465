import MyStatefulEditor from "./views/BlogEditor";
import Blogs from "./views/Blogs";

const dashboardBlogsRestrictRoutes = [

    {
        path: "/v1/edit_blogs",
        name: "Blog Adds",
        icon: "nc-icon nc-notes",
        component: MyStatefulEditor,
        layout: "/admin"
    },

    {
        path: "/v1/blogs",
        name: "Blogs",
        icon: "nc-icon nc-ruler-pencil",
        component: Blogs,
        layout: "/admin"
    },


];

export default dashboardBlogsRestrictRoutes;