import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import config from "constants/config";
import httpClientV1 from 'http-common-v1';
import PropTypes from 'prop-types';
import { useEffect, useId, useState } from 'react';
import {
    Button, Col, Form, Row
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import RichTextEditor from 'react-rte';

const MyStatefulEditor = ({ onChange }) => {

    const { id } = useParams();
    let history = useHistory()

    const [value, setValue] = useState(RichTextEditor.createEmptyValue());
    const [finalValue, setFinalValue] = useState(null);
    const [title, setTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

    const [imageUrl, setImageUrl] = useState('');
    const [createdBy, setCreateBy] = useState('');
    const [category, setCategory] = useState('');
    const [categoryList, setCategoryList] = useState('');
    const [showHtml, setShowHtml] = useState(false);
    const [htmValue, setHtmlValue] = useState(null);

    const isImageUrl = (url) => {
        return /\.(jpeg|jpg|gif|png|webp|svg)$/i.test(url);
    };
    const postTextAreaId = useId();

    const handleChange = (value) => {
        setValue(value);
        if (onChange) {
            // Send the changes up to the parent component as an HTML string.
            onChange(value.toString('html'));
            setHtmlValue(value.toString('html'));

        }
    };

    const getAllCategory = async () => {
        try {
            const allCategory = await httpClientV1.get(config.V1_AUTH_API_URL + "/blogs/categories/all")
            if (allCategory.data.data.length > 0) {
                setCategoryList(allCategory.data.data)
            }

        } catch (error) {
            console.log(error)
        }

    }

    const saveDetails = async (value) => {
        if (title === '') {
            toast.error("Please provide a title")
        } else if (imageUrl === '') {
            toast.error("Please provide a imageUrl")
        } else if (category === '') {
            toast.error("Please provide a category")
        } else if (value === null) {
            toast.error("Please add some value in text editor")
        } else if (metaDescription === '') {
            toast.error("Please provide a meta description")
        } else {

            const blogPayload = {
                categoryId: category,
                title,
                imageUrl,
                htmlContent: value,
                createdBy: (createdBy != '') ? createdBy : "dubpro",
                metaDescription
            }

            const blogId = sessionStorage.getItem("blogId");
            if (blogId === undefined || blogId === null) {

                try {
                    const allCategory = await httpClientV1.post(config.V1_AUTH_API_URL + "/blogs", blogPayload)
                    if (allCategory.status === 201) {
                        toast.success(allCategory.data.data)
                        sessionStorage.removeItem("id");
                        sessionStorage.removeItem("blogId")
                        history.push("blogs")
                    }
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                const id = sessionStorage.getItem("id");
                try {
                    const allCategory = await httpClientV1.patch(config.V1_AUTH_API_URL + "/blogs/" + id, blogPayload)
                    if (allCategory.status === 200) {
                        toast.success(allCategory.data.data);
                        sessionStorage.removeItem("id");
                        sessionStorage.removeItem("blogId");
                        history.push("blogs")
                    }
                } catch (error) {
                    toast.error(error.message)
                }
            }
        }
    }
    const getBlogDetails = async (value) => {
        try {
            const blogDetails = await httpClientV1.get(config.V1_AUTH_API_URL + "/blogs/" + value)
            if (blogDetails.status === 200) {
                const responseData = blogDetails.data.data;
                setTitle(responseData.title);
                setMetaDescription(responseData.metaDescription);
                setCategory(responseData.category.uuid);
                setImageUrl(responseData.imageUrl);
                setCreateBy(responseData.createdBy);
                setValue(RichTextEditor.createValueFromString(responseData.htmlContent, 'html'))
                setHtmlValue(responseData.htmlContent)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }



    useEffect(() => {
        // const [value, setValue] = useState(RichTextEditor.createValueFromString(initialContent, 'html'));
        const blogId = sessionStorage.getItem("blogId");

        getAllCategory();
        if (blogId && blogId != null) {
            getBlogDetails(blogId)
        }
    }, []);

    return (
        <div>
            <Row style={{ margin: '10px 0px' }}>
                <Form.Group>
                    <label htmlFor="exampleInputName" style={{ margin: '10px 0px' }}>
                        Title
                    </label>
                    <Form.Control
                        placeholder="Add a title"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(v) => setTitle(v.target.value)}

                    ></Form.Control>

                </Form.Group>
            </Row>

            <Row style={{ margin: '10px 0px' }}>
                <Form.Group>
                    <label htmlFor="exampleInputName" style={{ margin: '10px 0px' }}>
                        ImageUrl
                    </label>
                    <Form.Control
                        placeholder="Add a ImageUrl"
                        type="text"
                        name="title"
                        value={imageUrl}
                        onChange={(v) => setImageUrl(v.target.value)}
                    ></Form.Control>

                </Form.Group>
            </Row>

            <Row style={{ margin: '10px 0px' }}>
                <Form.Group>
                    <label htmlFor="exampleInputName" style={{ margin: '10px 0px' }}>
                        Meta Description
                    </label>
                    <Form.Control
                        placeholder="Add a Meta Description"
                        type="text"
                        name="metaDescription"
                        value={metaDescription}
                        onChange={(v) => setMetaDescription(v.target.value)}
                    ></Form.Control>

                </Form.Group>
            </Row>



            <Row style={{ margin: '10px 0px' }}>
                <Form.Group>
                    <label htmlFor="exampleInputName" style={{ margin: '10px 0px' }}>
                        Please select category
                    </label>
                    <Form.Select aria-label="Default select example"
                        onChange={(v) => setCategory(v.target.value)}
                    >
                        <option>Open this select menu</option>
                        {categoryList && categoryList.map((v) => {
                            return (<option value={v.uuid}>{v.name}</option>)

                        })}
                    </Form.Select>
                </Form.Group>
            </Row>

            <Row style={{ margin: '10px 0px' }}>
                <Form.Group>
                    <label htmlFor="exampleInputName" style={{ margin: '10px 0px' }}>
                        CreatedBy
                    </label>
                    <Form.Control
                        placeholder="Add a created By"
                        type="text"
                        name="title"
                        value={createdBy}
                        onChange={(v) => setCreateBy(v.target.value)}
                    ></Form.Control>

                </Form.Group>
            </Row>




            <div>
                <Row>
                    <Col md="2" style={{ margin: '10px 0px' }}>
                        <label style={{ marginRight: '10px', fontWeight: "800" }} >HTML MODE :- </label>
                        <BootstrapSwitchButton size="xs" checked={showHtml} onChange={(checked) => {
                            const valueDetails = checked;
                            setHtmlValue(value.toString('html'));
                            setShowHtml(valueDetails);
                        }} />

                    </Col>
                </Row>
                {!showHtml && <RichTextEditor
                    value={value}
                    placeholder={"Tell you store..."}
                    onChange={handleChange} // Use handleChange here to update state properly
                    editorStyle={{ height: '200px', overflow: 'auto', margin: '10px 0px' }}
                />}
                {showHtml && <>

                    <textarea
                        id="postContent"
                        name="postContent"
                        className="textArea"
                        value={htmValue}
                        onChange={(v) => {
                            setValue(RichTextEditor.createValueFromString(v.target.value, 'html'))
                            setHtmlValue(v.target.value)
                        }}

                    />
                </>}

            </div>

            <Button
                block
                variant="default"
                onClick={() => {
                    console.log(value.toString('html'), title, imageUrl, category)
                    setFinalValue(value.toString('html'));
                    saveDetails(value.toString('html'));

                }}
                style={{ float: 'right', margin: '10px' }}
            >
                Save
            </Button>
        </div>
    );
};

MyStatefulEditor.propTypes = {
    onChange: PropTypes.func
};

export default MyStatefulEditor;


