
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Footer from "components/Footer/Footer";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import dashboardBlogsRestrictRoutes from "ristrict.blogs.routes";
import dashboardRestrictRoutes from "ristrict.routes";

import routes from "routes";


import sidebarImage from "assets/img/sidebar-3.jpg";

const emailId = ["deepali.das.translator@deepsync.co", "sanjay.kumar.translator@deepsync.co"];
const emailIdBlogs = ["tanishka.jain@deepsync.co", "abhishek.singh@deepsync.co"]

function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [isFind, setIsFind] = React.useState(null);

  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
    const email = localStorage.getItem("email")
    const isFind = emailId.find(element => element === email);
    const ifBlogUser = emailIdBlogs.find(element => element === email);
    if (isFind != undefined) {
      setIsFind(1);
    } else if (ifBlogUser != undefined) {
      setIsFind(2);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">

        {isFind === null && <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />}
        {isFind != undefined && isFind === 1 && <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardRestrictRoutes} />}
        {isFind != undefined && isFind === 2 && <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardBlogsRestrictRoutes} />}


        dashboardRestrictBlogRoutes

        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <Footer />
        </div>
      </div>

    </>
  );
}

export default Admin;
