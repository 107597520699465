import V1Videos from "./views/V1Videos";


const dashboardRestrictRoutes = [

    {
        path: "/v1/videos",
        name: "Videos",
        icon: "nc-icon nc-note-03",
        component: V1Videos,
        layout: "/admin"
    },


];

export default dashboardRestrictRoutes;