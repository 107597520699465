import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import config from "constants/config";
import httpClientV1 from 'http-common-v1';
import { useEffect, useState } from "react";
import {
    Button, Dropdown, Row
} from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import toast from "react-hot-toast";
// react-bootstrap components
import {
    Card, Col, Container, Table
} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import moment from "../../node_modules/moment/moment";

function Blogs() {
    let history = useHistory()

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [blogs, setBlogs] = useState([]);
    const [item, setItem] = useState([])
    const [totalBlogCount, setBlogCount] = useState(0)
    let numberOfPages = 0;
    let isShow = false;
    const getAllBlogs = async (value) => {
        try {

            const getAllBlogs = await httpClientV1.get(`${config.V1_AUTH_API_URL}/blogs/all?page=${(value != undefined) ? value : page}&limit=${limit}`)
            if (getAllBlogs.status === 200) {
                const responseData = getAllBlogs.data.data;
                setBlogs(responseData.blogs);
                setBlogCount(responseData.totalCount);
                const activeValue = (value != "") ? value : page;
                mapPagination(responseData.totalCount, activeValue)
            }

        } catch (error) {
            toast.error(error.message)
        }

    }

    const updateBlogStatus = async (payload, id) => {
        try {
            const blogsDetails = await httpClientV1.patch(`${config.V1_AUTH_API_URL}/blogs/${id}`, payload);
            if (blogsDetails.status === 200) {
                await getAllBlogs();
            }
            toast.success("update successfully")

        } catch (error) {
            toast.error(error.message)
        }


    }
    const getApiCallPage = async (value) => {
        await setPage(value);
        await getAllBlogs(value);
    }


    const mapPagination = (totalCount, active) => {
        numberOfPages = Math.ceil(totalCount / limit);
        let items = []
        for (let number = 1; number <= numberOfPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={() => getApiCallPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setItem(items);
    }



    useEffect(() => {
        sessionStorage.removeItem("blogId");
        sessionStorage.removeItem("id")

        getAllBlogs();
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">

                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Blogs list</Card.Title>

                                </Row>

                                <Row>
                                    <Col md="10"></Col>


                                    <Col md="2">
                                        <Button variant="primary button_hig" onClick={() => { }}>Add Blogs</Button>
                                    </Col>



                                </Row>


                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">

                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">ID</th>
                                            <th className="border-0">Title</th>
                                            <th className="border-0">Category Name</th>
                                            <th className="border-0">Create By</th>

                                            <th className="border-0">Publish Date</th>
                                            <th className="border-0">Is Active</th>
                                            <th className="border-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            blogs.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.categoryName}</td>
                                                        <td>{item.createdBy}</td>
                                                        <td>{moment(item.publishDate).format("DD-MM-YYY")}</td>
                                                        <td><BootstrapSwitchButton checked={item.isActive} size="xs" onChange={(checked) => {
                                                            const value = checked;
                                                            updateBlogStatus({ isActive: value },
                                                                item.uuid)
                                                        }
                                                        } /></td>

                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                    Action
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => {
                                                                        sessionStorage.setItem("blogId", item.url)
                                                                        sessionStorage.setItem("id", item.uuid)
                                                                        history.push('edit_blogs')
                                                                    }}
                                                                    >Edit</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {item && <Row className="paginationValue">
                    <Pagination>{item}</Pagination>
                </Row>}
            </Container>


        </>
    );
}

export default Blogs;